<template>
	<div class="m_common_content">
		<div class="m_common_top">
			<div class="m_checkdata_content">
				<!--<el-select v-model="selectValue" placeholder="请选择合伙人" clearable>
					<el-option label="全部" value=""> </el-option>
					<el-option :label="item.name" :value="item.id" v-for="(item, i) in franchiseesData" :key="i">
					</el-option>
				</el-select>-->
				<el-input v-model="word" placeholder="请输入加盟商名称" clearable></el-input>
				<el-button @click="search" class="m_btn_search">搜索</el-button>
			</div>
			<div class="m_tools_content">
				<el-button @click="showInsert" class="m_add m_btn_common">添加</el-button>
			</div>
		</div>
		<div class="m_common_middle">
			<div class="m_table_content">
				<el-table :data="tableData" stripe>
					<el-table-column label="序号" type="index" width="60" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="name" label="名称" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="alias" label="简称" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="linker" label="联系人" show-overflow-tooltip>
					</el-table-column>
          <el-table-column prop="tel" label="电话" show-overflow-tooltip>
          </el-table-column>
					<el-table-column prop="email" label="邮箱" show-overflow-tooltip>
					</el-table-column>
					<el-table-column label="行政区域" show-overflow-tooltip>
						<template slot-scope="scope">
							{{ scope.row.province }}{{ scope.row.city }}{{ scope.row.county }}
						</template>
					</el-table-column>
					<el-table-column label="状态" show-overflow-tooltip>
						<template slot-scope="scope">
							<i v-if="scope.row.status === 'Valid'" style="color: #62c462">正常</i
              >
              <i v-else style="color: #ff4343">停用</i>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="80">
						<template slot-scope="scope">
							<i class="el-icon-edit-outline m_edit" @click="showUpdate(scope.row)"></i>
							<i class="el-icon-key m_edit" @click="showPwd(scope.row.id)" title="更改密码"></i>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<div class="m_common_bottom">
			<m-pagination @pageChange="pageChange" :total="total"></m-pagination>
		</div>
		<el-dialog :close-on-click-modal="false" :title="dialogTitle" :visible.sync="dialogVisible" :modal="false" v-el-drag-dialog width="600px">
			<div class="m_dialog_top" :style="dialogTopStyle">
				<el-form ref="form" :model="form" label-width="100px" :rules="rules" style="padding-right: 10px">
					<el-form-item label="logo" prop="logo">
						<el-upload class="avatar-uploader" :show-file-list="false" :action="fileUpLoadUrl" :headers="{ token: token }" :file-list="pictureList" :on-success="upLoadSuccess">
							<img v-if="form.logo" :src="fileUrl + form.logo" class="avatar" />
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</el-form-item>
					<el-form-item label="所属合伙人" prop="franchi">
						<el-select v-model="form.franchi" placeholder="请选择" clearable>
							<el-option :label="item.name" :value="item.id" v-for="(item, i) in franchiseesData" :key="i">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="加盟商名称" prop="name">
						<el-input v-model="form.name"></el-input>
					</el-form-item>
					<el-form-item label="简称" prop="alias">
						<el-input v-model="form.alias"></el-input>
					</el-form-item>
					<el-form-item label="联系人" prop="linker">
						<el-input v-model="form.linker"></el-input>
					</el-form-item>
          <el-form-item label="电话" prop="tel">
            <el-input v-model="form.tel"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
					<el-form-item label="所在省" prop="province">
						<el-select v-model="form.province" placeholder="请选择" @change="provinceChange" clearable>
							<el-option :label="item" :value="item" v-for="(item, i) in provinceList" :key="i">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="所在市" prop="city">
						<el-select v-model="form.city" placeholder="请选择" @change="cityChange" clearable>
							<el-option :label="item" :value="item" v-for="(item, i) in cityList" :key="i">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="所在区/县" prop="county">
						<el-select v-model="form.county" placeholder="请选择">
							<el-option :label="item" :value="item" v-for="(item, i) in countyList" :key="i">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="详细地址" prop="address">
						<el-input v-model="form.address"></el-input>
					</el-form-item>
					<el-form-item label="邮编" prop="zip">
						<el-input v-model="form.zip"></el-input>
					</el-form-item>
					<el-form-item label="企业信用号" prop="unicode">
						<el-input v-model="form.unicode"></el-input>
					</el-form-item>
					<el-form-item label="账号" prop="usercode" v-if="dialogTitle === '添加'">
						<el-input v-model="form.usercode"></el-input>
					</el-form-item>
					<el-form-item label="密码" prop="password" v-if="dialogTitle === '添加'">
						<el-input v-model="form.password"></el-input>
					</el-form-item>
					<el-form-item label="备注">
						<el-input v-model="form.note"></el-input>
					</el-form-item>

					<el-form-item label="启用状态" prop="status">
						<el-switch v-model="form.status" active-color="#13ce66" inactive-color="#ff4949" active-value="Valid" inactive-value="Invalid">
						</el-switch>
					</el-form-item>
					<el-form-item label="营业执照" prop="licimg">
						<el-upload class="avatar-uploader" :show-file-list="false" :action="fileUpLoadUrl" :headers="{ token: token }" :file-list="pictureList1" :on-success="upLoadSuccess1">
							<img v-if="form.licimg" :src="fileUrl + form.licimg" class="avatar" />

							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</el-form-item>
					<el-form-item label="经营许可证" prop="certimg">
						<el-upload class="avatar-uploader" :show-file-list="false" :action="fileUpLoadUrl" :headers="{ token: token }" :file-list="pictureList2" :on-success="upLoadSuccess2">
							<img v-if="form.certimg" :src="fileUrl + form.certimg" class="avatar" />
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</el-form-item>
				</el-form>
			</div>
			<div class="m_dialog_bottom">
				<el-button type="primary" @click="save" size="mini" class="m_btn_save">保存</el-button>
				<el-button @click="dialogVisible = false" size="mini" class="m_btn_cancel">取消</el-button>
			</div>
		</el-dialog>
		<el-dialog :close-on-click-modal="false" title="修改密码" :visible.sync="pwdDialogVisible" :modal="false" v-el-drag-dialog width="600px">
			<div class="m_dialog_top" :style="pwdDialogTopStyle">
				<el-form ref="pwdForm" :model="pwdForm" label-width="100px" :rules="pwdRules" style="padding-right: 10px">
					<el-form-item label="新密码" prop="password">
						<el-input v-model="pwdForm.password"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div class="m_dialog_bottom">
				<el-button type="primary" @click="savePwd" size="mini" class="m_btn_save">保存</el-button>
				<el-button @click="pwdDialogVisible = false" size="mini" class="m_btn_cancel">取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import elDragDialog from "@/directive/el-drag-dialog";
	import mPagination from "@/components/pagination/index.vue";
	import { PAGE_SIZE } from "@/utils/config";
	import { fileUpLoadUrl } from "@/api/base.js";
	import { XHR_URL } from "@/utils/config";
	import {
		operatorsSelect,
		operatorsInsert,
		operatorsUpdate,
		operatorsDelete,
		editPassWord
	} from "@/api/franchisee";
	import { regions } from "@/api/base";
	import { franchiseesSelect} from "@/api/partner";
	export default {
		directives: {
			elDragDialog
		},
		data() {
			return {
				provinceList: [],
				cityList: [],
				countyList: [],
				pictureList: [],
				pictureList1: [],
				pictureList2: [],
				franchiseesData: [],
				total: 0,
				currentPage: 1,
				word: null,
				selectValue: null,
				tableData: [],
				dialogTitle: "添加",
				dialogVisible: false,
				rules: {
					franchi: [{
						required: true,
						message: "合伙人不能为空",
						trigger: "change"
					}, ],
					name: [{
							required: true,
							message: "加盟商名称不能为空",
							trigger: "blur"
						},
						{
							min: 1,
							max: 128,
							message: "加盟商名称过长",
							trigger: "change"
						},
						{
							min: 1,
							max: 128,
							message: "加盟商名称过长",
							trigger: "blur"
						},
					],
					alias: [{
							required: true,
							message: "加盟商简称不能为空",
							trigger: "blur"
						},
						{
							min: 1,
							max: 128,
							message: "加盟商简称过长",
							trigger: "change"
						},
						{
							min: 1,
							max: 128,
							message: "加盟商简称过长",
							trigger: "blur"
						},
					],
					linker: [{
							required: true,
							message: "联系人不能为空",
							trigger: "blur"
						},
						{
							min: 1,
							max: 128,
							message: "联系人过长",
							trigger: "change"
						},
						{
							min: 1,
							max: 128,
							message: "联系人过长",
							trigger: "blur"
						},
					],
          tel: [
            { required: true, message: "内容不能为空", trigger: "blur" },
          ],
					email: [{
							required: true,
							message: "邮箱不能为空",
							trigger: "blur"
						},
						{
							min: 1,
							max: 128,
							message: "邮箱过长",
							trigger: "change"
						},
						{
							min: 1,
							max: 128,
							message: "邮箱过长",
							trigger: "blur"
						},
					],
					province: [{
						required: true,
						message: "省不能为空",
						trigger: "change"
					}, ],
					city: [{
						required: true,
						message: "市不能为空",
						trigger: "change"
					}],
					county: [{
						required: true,
						message: "区/县不能为空",
						trigger: "change"
					}, ],
					address: [{
							required: true,
							message: "详细地址不能为空",
							trigger: "blur"
						},
						{
							min: 1,
							max: 128,
							message: "详细地址过长",
							trigger: "change"
						},
						{
							min: 1,
							max: 128,
							message: "详细地址过长",
							trigger: "blur"
						},
					],
					zip: [{
							required: true,
							message: "邮编不能为空",
							trigger: "blur"
						},
						{
							min: 1,
							max: 128,
							message: "邮编过长",
							trigger: "change"
						},
						{
							min: 1,
							max: 128,
							message: "邮编过长",
							trigger: "blur"
						},
					],
					unicode: [{
							required: true,
							message: "企业信用号不能为空",
							trigger: "blur"
						},
						{
							min: 1,
							max: 128,
							message: "企业信用号过长",
							trigger: "change"
						},
						{
							min: 1,
							max: 128,
							message: "企业信用号过长",
							trigger: "blur"
						},
					],
					usercode: [{
							required: true,
							message: "账号不能为空",
							trigger: "blur"
						},
						{
							min: 1,
							max: 128,
							message: "账号过长",
							trigger: "change"
						},
						{
							min: 1,
							max: 128,
							message: "账号过长",
							trigger: "blur"
						},
					],
					password: [{
							required: true,
							message: "密码不能为空",
							trigger: "blur"
						},
						{
							min: 1,
							max: 128,
							message: "密码过长",
							trigger: "change"
						},
						{
							min: 1,
							max: 128,
							message: "密码过长",
							trigger: "blur"
						},
					],
					licimg: [{
						required: true,
						message: "营业执照不能为空",
						trigger: "blur"
					}],
					certimg: [{
						required: true,
						message: "经营许可证不能为空",
						trigger: "blur"
					}],
					logo: [{
						required: true,
						message: "加盟商logo不能为空",
						trigger: "blur"
					}],
					//      note: [
					//        { required: true, message: "内容不能为空", trigger: "blur" },
					//        { min: 1, max: 128, message: "内容过长", trigger: "change" },
					//        { min: 1, max: 128, message: "内容过长", trigger: "blur" },
					//      ],
					//      status: [
					//        { required: true, message: "内容不能为空", trigger: "change" },
					//      ],
				},
				form: {
					id: "0",
					franchi: "",
					name: "",
					alias: "",
					linker: "",
					email: "",
					province: "",
					city: "",
					county: "",
					address: "",
					zip: "",
					unicode: "",
					usercode: "",
					password: "",
					status: true,
					licimg: "",
					certimg: "",
					logo: "",
					note: "",
          			tel: "",
				},
				pwdDialogVisible:false,
			    pwdForm: {
						id: 0,
						password: '',
				},
				pwdRules: {
					password: [{
						required: true,
						message: "内容不能为空",
						trigger: "blur"
					}]
				}
			};
		},
		props: {},
		computed: {
			pageSize() {
				return PAGE_SIZE;
			},
			fileUrl() {
				return XHR_URL;
			},
			token() {
				return this.$store.getters.token;
			},
			fileUpLoadUrl() {
				return fileUpLoadUrl;
			},
			dialogTopStyle() {
				return {
					maxHeight: document.body.offsetHeight * 0.6 + "px",
				};
			},
			pwdDialogTopStyle() {
				return {
					maxHeight: document.body.offsetHeight * 0.6 + "px",
					height: '100px'
				};
			}
		},
		components: {
			mPagination,
		},
		async created() {
			this.select();
			this.franchiseesSelect();
			const res = await this.regions();
			this.provinceList = res;
		},
		mounted() {},
		methods: {
			showPwd(id) {
				this.pwdForm.id = id;
				this.pwdDialogVisible = true;
			},
			upLoadSuccess(response) {
				console.log(response);
				this.pictureList = [];
				this.form.logo = response.results[0].url;
			},
			upLoadSuccess1(response) {
				console.log(response);
				this.pictureList1 = [];
				this.form.licimg = response.results[0].url;
			},
			upLoadSuccess2(response) {
				console.log(response);
				this.pictureList2 = [];
				this.form.certimg = response.results[0].url;
			},
			pageChange(page) {
				this.currentPage = page;
				this.select();
			},
			search() {
				this.select();
			},
			showInsert() {
				this.initForm();
				this.dialogTitle = "添加";
				this.dialogVisible = true;
			},
			showUpdate(data) {
				this.initForm();
				this.dialogTitle = "修改";
				this.form = JSON.parse(JSON.stringify(data));
				this.form.franchi = this.form.franchisee.id;
				this.dialogVisible = true;
			},
			initForm() {
				if(this.$refs.form) {
					this.$refs.form.resetFields();
				}
				this.form = {
					id: "0",
					franchi: "",
					name: "",
					alias: "",
					linker: "",
					email: "",
					province: "",
					city: "",
					county: "",
					address: "",
					zip: "",
					unicode: "",
					usercode: "",
					password: "",
					status: "",
					licimg: "",
					certimg: "",
					logo: "",
					note: "",
          tel: "",
				};
			},
			save() {
				this.$refs.form.validate((valid) => {
					if(valid) {
						switch(this.dialogTitle) {
							case "添加":
								this.insert();
								break;
							case "修改":
								this.update();
								break;
						}
					} else {
						return false;
					}
				});
			},
			savePwd() {
				this.$refs.pwdForm.validate((valid) => {
					if(valid) {
						this.updatePwd();
					} else {
						return false;
					}
				});
			},
			async select() {
				const res = await operatorsSelect({
					name: this.word,
					franchi: this.selectValue,
					pageNo: this.currentPage,
					pageSize: this.pageSize,
				});
				const {
					pageCount,
					results
				} = res;
				this.tableData = results;
				this.total = pageCount.allCount;
			},
			async insert() {
				const res = await operatorsInsert(this.form);
				const {
					pageCount,
					results
				} = res;
				this.$message({
					message: "添加成功",
					type: "success",
				});
				this.select();
				this.dialogVisible = false;
			},
			async update() {
				const res = await operatorsUpdate(this.form);
				const {
					pageCount,
					results
				} = res;
				this.$message({
					message: "修改成功",
					type: "success",
				});
				this.select();
				this.dialogVisible = false;
			},
			async updatePwd() {
				const res = await editPassWord(this.pwdForm);
				const {
					pageCount,
					results
				} = res;
				this.$message({
					message: "修改成功",
					type: "success",
				});
				this.pwdDialogVisible = false;
			},
			async franchiseesSelect() {
				const res = await franchiseesSelect({});
				const {
					pageCount,
					results
				} = res;
				this.franchiseesData = results;
			},
			async provinceChange(val) {
				this.form.city='';
				this.cityList = [];
				const res = await this.regions({
					province: val,
				});
				this.cityList = res;
			},
			async cityChange(val) {
				this.form.county='';
				this.countyList = [];
				const res = await this.regions({
					province: this.form.province,
					city: val,
				});
				this.countyList = res;
			},
			async regions(data) {
				const res = await regions(data);
				const {
					pageCount,
					results
				} = res;
				return results;
			},
		},
		beforeDestroy() {},
		destroyed() {},
	};
</script>
<style lang="less" scoped>
	@import "../../assets/css/common/common.less";
</style>
<style lang="less">
	@import "../../assets/css/checkdata/checkdata.less";
</style>